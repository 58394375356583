import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const LaptopPng = () => {
  const data = useStaticQuery(graphql`
    query {
      laptop: file(relativePath: { eq: "laptop@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)
  return (
    <Img
      className="solverLaptop"
      fluid={data.laptop.childImageSharp.fluid}
      alt="Laptop Computer."
    />
  )
}

export default LaptopPng
