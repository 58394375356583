import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { buttonTwo, buttonTwoAlt } from "../../Utilities"

const ButtonStyled = styled.a`
  ${buttonTwo};
  ${props => props.color === "alt" && buttonTwoAlt}
`

const AnchorTwo = ({ color, children, url }) => {
  return (
    <>
      <ButtonStyled
        target="_blank"
        rel="noopener noreferrer"
        href={url}
        color={color}
      >
        {children}
      </ButtonStyled>
    </>
  )
}

AnchorTwo.propTypes = {
  children: PropTypes.node.isRequired,
  url: PropTypes.node.isRequired,
}

export default AnchorTwo
