import React from "react"
import styled from "styled-components"

const SvgIcon = styled.div`
  svg {
    width: 100%;
    max-width: 100%;
  }
  .cls-1 {
    fill: #9bc230;
  }

  .cls-2 {
    fill: #1a1818;
  }

  .cls-3 {
    fill: #0059a8;
  }

  .cls-4 {
    fill: none;
  }
`

const SolverLogoSvg = () => {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="168"
        height="83.021"
        viewBox="0 0 168 83.021"
      >
        <g data-name="Group 3249" transform="translate(-304 -4949)">
          <g data-name="Solver Logo-Colour" transform="translate(0 -20)">
            <g data-name="Group 3171" transform="translate(181.987 4664.843)">
              <path
                d="M386.554 307.679l29.554 34.214a56.352 56.352 0 00-45.221-.468z"
                className="cls-1"
                data-name="Path 3304"
                transform="translate(-136.839 -.104)"
              ></path>
              <path
                d="M131.953 415.585c1.962 0 4.008 1.018 6.08 3.032l.154.15 2.858-2.95-.157-.153c-2.909-2.808-5.847-4.231-8.733-4.231a8.608 8.608 0 00-6.221 2.39 8.038 8.038 0 00-2.465 6 8.249 8.249 0 001.546 4.923 16.288 16.288 0 005.763 4.322 17.878 17.878 0 014.937 3.237 4.444 4.444 0 011.06 2.9 4.534 4.534 0 01-1.609 3.442 5.616 5.616 0 01-3.919 1.478c-2.2 0-4.32-1.131-6.294-3.362l-.163-.182-2.777 3.155.109.141a11.269 11.269 0 004.1 3.267 11.893 11.893 0 005.194 1.187 9.374 9.374 0 006.831-2.7 8.946 8.946 0 002.732-6.595 8.316 8.316 0 00-1.605-4.982c-1.077-1.441-3.129-2.948-6.1-4.491a15.857 15.857 0 01-4.712-3.207 4.233 4.233 0 01-1.086-2.729 3.714 3.714 0 011.311-2.822 4.526 4.526 0 013.166-1.216"
                className="cls-2"
                data-name="Path 3305"
                transform="translate(0 -57.152)"
              ></path>
              <path
                d="M194.984 411.44a15.559 15.559 0 00-12.015 5.262 16.758 16.758 0 00.243 22.724 16.537 16.537 0 0023.516 0 16.8 16.8 0 00.243-22.754 15.572 15.572 0 00-11.986-5.232m-.014 4.318a11.306 11.306 0 018.344 3.6 12.023 12.023 0 013.512 8.7 12.428 12.428 0 01-1.593 6.139 11.37 11.37 0 01-4.3 4.388 12.268 12.268 0 01-11.918 0 11.348 11.348 0 01-4.306-4.388 12.391 12.391 0 01-1.594-6.139 12.061 12.061 0 013.5-8.7 11.29 11.29 0 018.36-3.6"
                className="cls-2"
                data-name="Path 3306"
                transform="translate(-31.14 -57.156)"
              ></path>
              <path
                d="M0 0h4.408v38.865H0z"
                className="cls-2"
                data-name="Rectangle 1975"
                transform="translate(187.922 347.518)"
              ></path>
              <path
                d="M306.992 435.4l-10.224-22.2h-4.705l14.419 31.306h1.013l14.189-31 .143-.307h-4.736z"
                className="cls-2"
                data-name="Path 3307"
                transform="translate(-93.499 -58.123)"
              ></path>
              <path
                d="M399.33 428.194a16.994 16.994 0 00-3.453-10.687 15.5 15.5 0 00-12.783-6.07 15.018 15.018 0 00-12.411 5.927 16.782 16.782 0 00-3.651 10.6 16.982 16.982 0 004.313 11.337c2.868 3.338 6.9 5.027 11.981 5.027a17.2 17.2 0 006.182-1.051 15.351 15.351 0 004.926-3.065 18.38 18.38 0 003.853-5.283l.1-.189-3.717-1.951-.109.171a18.45 18.45 0 01-3.335 4.331 11.954 11.954 0 01-3.725 2.137 13.05 13.05 0 01-4.516.8 10.8 10.8 0 01-8.053-3.371 12.356 12.356 0 01-3.374-8.451h27.779zm-27.307-3.65a12.831 12.831 0 013.462-6.007 11.164 11.164 0 017.669-2.865 12.172 12.172 0 015.164 1.142 10.834 10.834 0 013.94 3 12.748 12.748 0 012.206 4.727z"
                className="cls-2"
                data-name="Path 3308"
                transform="translate(-134.719 -57.154)"
              ></path>
              <path
                d="M470.09 412.514a7.5 7.5 0 00-3.624-1.074 7.819 7.819 0 00-4.32 1.352 13.834 13.834 0 00-3.493 3.484v-4.044h-4.493v31.306h4.493v-10.66c0-5.31.244-8.877.728-10.611a10.172 10.172 0 012.743-4.962 5.592 5.592 0 013.689-1.575 5.364 5.364 0 012.012.519l.175.075 2.289-3.7z"
                className="cls-2"
                data-name="Path 3309"
                transform="translate(-182.625 -57.156)"
              ></path>
              <path
                d="M325.952 307.489l-19.065 33.381s-12.22-13.245-45.567-4.621z"
                className="cls-3"
                data-name="Path 3310"
                transform="translate(-76.596)"
              ></path>
            </g>
          </g>
          <path
            d="M0 0h168v83H0z"
            className="cls-4"
            data-name="Rectangle 2015"
            transform="translate(304 4949)"
          ></path>
        </g>
      </svg>
    </SvgIcon>
  )
}

export default SolverLogoSvg
