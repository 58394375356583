import React from "react"
import styled from "styled-components"

const SvgIcon = styled.div`
  svg {
    width: 100%;
    max-width: 100%;
  }

  .cls-1 {
    fill: #a8c4e5;
    opacity: 0.4;
    mix-blend-mode: multiply;
  }

  .cls-1,
  .cls-20,
  .cls-24,
  .cls-25 {
    isolation: isolate;
  }

  .cls-2 {
    fill: #2c4482;
  }

  .cls-3 {
    fill: #ffc2b0;
  }

  .cls-4 {
    fill: #88bdeb;
  }

  .cls-5 {
    fill: #283d75;
  }

  .cls-6,
  .cls-7 {
    fill: #fff;
  }

  .cls-7 {
    opacity: 0.46;
  }

  .cls-8 {
    fill: #513554;
  }

  .cls-9 {
    fill: #fcfeff;
  }

  .cls-10 {
    fill: #4264bf;
  }

  .cls-11 {
    fill: #dbefff;
  }

  .cls-12 {
    fill: #715154;
  }

  .cls-13 {
    fill: #dcefff;
  }

  .cls-14 {
    fill: #038a96;
  }

  .cls-15 {
    fill: #66223d;
  }

  .cls-16 {
    fill: #715255;
  }

  .cls-17 {
    fill: #ea8a6c;
  }

  .cls-18 {
    fill: #5366c4;
  }

  .cls-19 {
    fill: #97cbff;
  }

  .cls-20 {
    fill: #9ecc45;
  }

  .cls-21 {
    fill: #5986ff;
  }

  .cls-22 {
    fill: #ebd265;
  }

  .cls-23 {
    fill: #dea749;
  }

  .cls-24 {
    fill: #1c6bb5;
  }

  .cls-25 {
    fill: #6c9b45;
  }

  .cls-26 {
    fill: #50dec6;
  }

  .cls-27 {
    fill: #45bfab;
  }
`

const ContactFormSvg = () => {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="275.822"
        height="263.947"
        viewBox="0 0 275.822 263.947"
      >
        <g data-name="Contact - Icon" transform="translate(-248.454 -5102.295)">
          <g transform="translate(169.275 4443.474)">
            <g data-name="Group 3160" transform="translate(183.962 658.822)">
              <ellipse
                cx="16.227"
                cy="9.368"
                className="cls-1"
                data-name="Ellipse 176"
                rx="16.227"
                ry="9.368"
                transform="rotate(-.311 20597.166 -8779.484)"
              ></ellipse>
              <ellipse
                cx="16.227"
                cy="9.368"
                className="cls-1"
                data-name="Ellipse 177"
                rx="16.227"
                ry="9.368"
                transform="rotate(-.311 18136.386 -1177.761)"
              ></ellipse>
              <path
                d="M725.622 626.673l6.949 1.417.935 2.412c.224 1.729.212 2.649-.935 3.561-.964.767-2.931.175-3.958-.3l-4.463-2.684c-3.309-1.823-3.059-3.173-2.7-4.288.528-1.254 2.058-.964 4.172-.118z"
                className="cls-2"
                data-name="Path 3221"
                transform="translate(-657.17 -513.112)"
              ></path>
              <path
                d="M694.062 643.68l6.718-2.273 2.022 1.614c1.063 1.381 1.516 2.182.984 3.548-.447 1.148-2.445 1.626-3.573 1.729l-5.208-.074c-3.777.09-4.241-1.2-4.493-2.346-.171-1.346 1.297-1.866 3.55-2.198z"
                className="cls-2"
                data-name="Path 3222"
                transform="translate(-640.367 -521.562)"
              ></path>
              <path
                d="M645.023 595.158l-6.42-3.089-3.35.114c-.2 4.432.177 3.822 1.627 4.649l5.449 2.014c2.793.694 3.632.68 4.98-.273.7-1.137-.403-2.2-2.286-3.415z"
                className="cls-2"
                data-name="Path 3223"
                transform="translate(-610.149 -494.601)"
              ></path>
              <path
                d="M607.691 614.1l-2.181-6.785-2.389-2.351c-3.355 2.909-2.651 2.764-2.251 4.385l2.292 5.34c1.42 2.5 2.007 3.1 3.628 3.423 1.31-.276 1.317-1.812.901-4.012z"
                className="cls-2"
                data-name="Path 3224"
                transform="translate(-591.197 -501.647)"
              ></path>
              <g data-name="Group 3143" transform="translate(73.745 35.118)">
                <path
                  d="M746.233 455.48c3.79 5.745 5.463 13.074 6.961 19.706a6.571 6.571 0 01.067 3.682c-1.265 4.66-2.7 9.016-3.244 13.874-.275 2.449-4.151 2.477-3.873 0a64.711 64.711 0 012.811-13.275c.271-.813.566-1.615.876-2.413-.284.732.1 1.271-.052.618-.092-.409-.177-.819-.268-1.228-1.43-6.389-2.975-13.482-6.622-19.009-1.376-2.086 1.976-4.028 3.344-1.955z"
                  className="cls-3"
                  data-name="Path 3225"
                  transform="translate(-742.564 -454.593)"
                ></path>
              </g>
              <path
                d="M745.711 456.571c2.562 4.466 4.42 12.014 6.106 18.127.639 1.687.236 3.213-.831 3.145l-3.567.493c-.88-.056-2.553-1.958-2.964-3.37l-4.523-15.542c-.736-2.529-.193-4.7 1.256-5.029l.33-.049c.253-.931 2.261-.946 4.193 2.225z"
                className="cls-4"
                data-name="Path 3226"
                transform="translate(-667.202 -419.044)"
              ></path>
              <path
                d="M725.857 504.35l17.894 10.331a1.315 1.315 0 001.973-1.138v-12.461a1.314 1.314 0 00-.658-1.138l-17.894-10.332a1.315 1.315 0 00-1.973 1.139v12.461a1.315 1.315 0 00.658 1.138z"
                className="cls-5"
                data-name="Path 3227"
                transform="translate(-659.329 -438.515)"
              ></path>
              <path
                d="M741.517 512.62a5.021 5.021 0 002.28 3.931c1.259.727 2.279.145 2.279-1.3a5.024 5.024 0 00-2.279-3.932c-1.26-.726-2.28-.144-2.28 1.301z"
                className="cls-6"
                data-name="Path 3228"
                transform="translate(-668.246 -450.304)"
              ></path>
              <path
                d="M746.585 535.431l1.3.589a2 2 0 002.642-1 2 2 0 00-1-2.642l-1.3-.589a2 2 0 00-2.642 1 2 2 0 001 2.642z"
                className="cls-3"
                data-name="Path 3229"
                transform="translate(-670.375 -461.566)"
              ></path>
              <path
                d="M714.7 426.373l-.027.732s-.1 2.206 2.076 1.065 2.1-3.893 2.1-3.893-1.649-.194-4.149 2.096z"
                className="cls-7"
                data-name="Path 3230"
                transform="translate(-653.578 -402.907)"
              ></path>
              <path
                d="M718.462 422.97s-2.009-.13-5.167 2.585l-.084.876.023-.011s-.1 2.574 2.732 1.1a4.757 4.757 0 002.496-4.55zm-2.536 4.169c-2.107 1.1-2.033-.817-2.033-.817l.02-.634c2.419-2.1 4.03-2.02 4.03-2.02a3.943 3.943 0 01-2.016 3.47z"
                className="cls-8"
                data-name="Path 3231"
                transform="translate(-652.778 -402.194)"
              ></path>
              <path
                d="M710.926 429.589l1.684-.768.091-.953-1.684.768z"
                className="cls-8"
                data-name="Path 3232"
                transform="translate(-651.529 -404.871)"
              ></path>
              <path
                d="M724.174 423.495l.3-.136a.673.673 0 00.33-.507c.019-.205-.1-.312-.259-.238l-.3.136a.673.673 0 00-.33.507c-.015.206.096.312.259.238z"
                className="cls-8"
                data-name="Path 3233"
                transform="translate(-658.626 -401.987)"
              ></path>
              <path
                d="M700.166 434.449l.3-.136a.67.67 0 00.33-.506c.02-.206-.1-.312-.259-.238l-.3.136a.671.671 0 00-.33.507c-.02.205.093.311.259.237z"
                className="cls-8"
                data-name="Path 3234"
                transform="translate(-645.507 -407.974)"
              ></path>
              <g data-name="Group 3148" transform="translate(54.732 23.586)">
                <g data-name="Group 3147">
                  <g data-name="Group 3145">
                    <g data-name="Group 3144">
                      <path
                        d="M702.623 433.862a5.206 5.206 0 003.2-3.809l.023-.01.084-.876a8.631 8.631 0 00-5.122 2.111s-.982 3.68 1.815 2.584zm-1.388-2.318a6.71 6.71 0 013.994-1.641l-.105.673a3.87 3.87 0 01-2.382 2.831c-2.081.816-1.507-1.862-1.507-1.862z"
                        className="cls-2"
                        data-name="Path 3235"
                        transform="translate(-700.642 -429.167)"
                      ></path>
                    </g>
                  </g>
                  <g data-name="Group 3146" transform="translate(3.363 1.079)">
                    <path
                      d="M708.788 432.361c.07-.084.092-.19.049-.238l-.5-.554c-.043-.048-.135-.018-.205.065-.07.084-.092.19-.048.238l.5.554c.042.049.134.019.204-.065z"
                      className="cls-2"
                      data-name="Path 3236"
                      transform="translate(-708.058 -431.547)"
                    ></path>
                  </g>
                </g>
              </g>
              <path
                d="M722.062 426.043c.07-.084.092-.19.049-.238l-.5-.554c-.043-.048-.135-.018-.205.066-.07.084-.091.19-.048.238l.5.554c.042.047.134.018.204-.066z"
                className="cls-9"
                data-name="Path 3237"
                transform="translate(-657.216 -403.428)"
              ></path>
              <g data-name="Group 3150" transform="translate(56.821 75.958)">
                <g data-name="Group 3149">
                  <path
                    d="M713.91 548.315c-2.576 7.556-2.813 15.68-2.571 23.588.181 5.893 1.111 11.838.964 17.733-.1 3.866-6.116 3.881-6.02 0 .152-6.058-.813-12.164-.978-18.223-.225-8.243.118-16.829 2.8-24.7 1.243-3.646 7.058-2.08 5.8 1.6z"
                    className="cls-10"
                    data-name="Path 3238"
                    transform="translate(-705.248 -544.643)"
                  ></path>
                </g>
              </g>
              <g data-name="Group 3152" transform="translate(68.651 69.121)">
                <g data-name="Group 3151">
                  <path
                    d="M740.27 533.295a88.714 88.714 0 00-2.451 15.02 43.147 43.147 0 00-.329 8.812c.632 5.992 1.748 12.1 1.707 18.135-.027 3.872-6.046 3.88-6.019 0 .042-6.127-1.1-12.319-1.735-18.4a44.885 44.885 0 01.414-9.122 96 96 0 012.61-16.043c1.026-3.73 6.835-2.146 5.8 1.6z"
                    className="cls-10"
                    data-name="Path 3239"
                    transform="translate(-731.33 -529.567)"
                  ></path>
                </g>
              </g>
              <path
                d="M722.2 537.086c-.556.623 1.832-2.407 1.118-1.976-1.489.9-9.845 6.577-11.584 6.606-2.1.035-.876-2-2.439-3.4-1.483-1.331-1.833-3.494-1.977-5.482a3.44 3.44 0 01.152-1.629 3.51 3.51 0 011.2-1.292c3.712-2.81 11.014-9.764 16.2-7.54 5.996 2.572-.008 11.727-2.67 14.713z"
                className="cls-10"
                data-name="Path 3240"
                transform="translate(-649.543 -456.28)"
              ></path>
              <path
                d="M724.479 454.628c1.1 1.417.969 2.293.916 4.227a4.322 4.322 0 01-3.155 4.04l-18.607 5.219s-1.069-7.159 2.6-9.313a642.74 642.74 0 007.453-4.484c3.54-2.155 8.255-2.962 10.793.311z"
                className="cls-11"
                data-name="Path 3241"
                transform="translate(-647.465 -418.295)"
              ></path>
              <path
                d="M724.013 448.287l-1.574-7.014-4.279 2.88 1.289 6.386c.125 1.037 4.873.131 4.564-2.252z"
                className="cls-3"
                data-name="Path 3242"
                transform="translate(-655.483 -412.197)"
              ></path>
              <path
                d="M714.331 417.991s4.289-2.987 4.33 4.273a12.662 12.662 0 01-7.181 11.4c-2.58 1.227-5.734-.345-5.875-2.86-.615-3.668-.624-7.1.579-10.049-.17-3.032 1.916-1.953 5.521-2.149z"
                className="cls-3"
                data-name="Path 3243"
                transform="translate(-648.398 -399.141)"
              ></path>
              <path
                d="M716.118 430.253s4.961-1.842 4.49-8.821c-.51-7.553-7.456-6.9-11.318-7.273-1.709-.164-2.9-.41-4.649 1.151-1.592 1.42-.1 4.611 2.181 4.817.905.082.491 2.521.674 3.357.325 1.488 1.252 2.216 1.595 3.658.324 1.365 2.337 3.5 3.646 3.656a4.836 4.836 0 003.381-.545z"
                className="cls-12"
                data-name="Path 3244"
                transform="translate(-647.733 -397.314)"
              ></path>
              <path
                d="M726.5 492.8c1.185-.458.9-20.817-.163-32.061l-20.945 9.966c.105 9.571 1.314 17.125.168 28.789z"
                className="cls-11"
                data-name="Path 3245"
                transform="translate(-648.506 -422.835)"
              ></path>
              <path
                d="M712.82 536.918c5.175-1.888 10.1-3.954 13-7.506 1.422-.822 1.242-2.269-.4-3.218a6.217 6.217 0 00-5.573-.231l-13 7.506c-1.422.821-1.242 2.269.4 3.217a6.218 6.218 0 005.573.232z"
                className="cls-11"
                data-name="Path 3246"
                transform="translate(-648.776 -458.174)"
              ></path>
              <path
                d="M705.394 480.736l3.451-1.643c.142 9.331 1.285 16.826.159 28.28l.058-.018a2.25 2.25 0 001.358 1.869 6.216 6.216 0 005.573.231 59.691 59.691 0 007.083-2.988c-2.857 2.189-6.621 3.716-10.525 5.14a6.218 6.218 0 01-5.573-.231 2.252 2.252 0 01-1.358-1.869l-.057.018c1.145-11.664-.063-19.218-.169-28.789z"
                className="cls-11"
                data-name="Path 3247"
                transform="translate(-648.506 -432.864)"
              ></path>
              <path
                d="M700.279 435.047l6.4.613a.417.417 0 00.454-.374.417.417 0 00-.374-.454l-6.4-.613a.417.417 0 00-.454.374.417.417 0 00.374.454z"
                className="cls-2"
                data-name="Path 3248"
                transform="translate(-645.506 -408.341)"
              ></path>
              <path
                d="M714.883 457.441c4.805-.723 8.051 2.3 9.2-4.622a1.535 1.535 0 00-2.075.454 4.786 4.786 0 01-4.516 1.707 1.524 1.524 0 00-1.466.688z"
                className="cls-13"
                data-name="Path 3249"
                transform="translate(-653.692 -418.382)"
              ></path>
              <g data-name="Group 3153" transform="translate(37.405 43.462)">
                <path
                  d="M685.432 474.85a32.8 32.8 0 01-2.639 11.341c-1.2 2.64-2.438 6.418-5.045 8.083-5.882 3.757-12.152-5.867-14.931-9.529-1.506-1.985 1.855-3.917 3.344-1.955 1.574 2.075 7.385 11.163 10.381 7.243 3.2-4.185 4.6-10.074 5.016-15.183.2-2.467 4.076-2.489 3.873 0z"
                  className="cls-3"
                  data-name="Path 3250"
                  transform="translate(-662.438 -472.992)"
                ></path>
              </g>
              <path
                d="M702.547 472.7c-1.172 9.9-4.294 16.2-8.62 19.407-2.275 1.259-4.4-2.331-3.534-4.5 4.308-3.83 5.82-9.8 6.23-16.623a3.121 3.121 0 013.7-1.731 2.918 2.918 0 012.224 3.447z"
                className="cls-4"
                data-name="Path 3251"
                transform="translate(-640.2 -427.43)"
              ></path>
              <g data-name="Group 3154" transform="translate(27.381 21.297)">
                <path
                  d="M644.209 425.99a48.559 48.559 0 003.073 16.969c1.621 4.115 4.455 7.911 6.8 11.619 1.337 2.113-2.014 4.058-3.344 1.955-2.53-4-5.532-8.067-7.192-12.544a54.241 54.241 0 01-3.212-18c-.058-2.493 3.815-2.492 3.873 0z"
                  className="cls-3"
                  data-name="Path 3252"
                  transform="translate(-640.336 -424.12)"
                ></path>
              </g>
              <path
                d="M638.97 416.317c1.7 2.68 1.879 6.816 2.379 10.719a1.72 1.72 0 01-1.452 2.227l-4.19 1.594c-.875.075-1.684-1.792-1.821-2.69l-1.508-9.893a3.157 3.157 0 012.25-3.552 3.564 3.564 0 014.342 1.595z"
                className="cls-14"
                data-name="Path 3253"
                transform="translate(-608.585 -397.578)"
              ></path>
              <path
                d="M613.413 417.778l9.255-2.292-1.342-4.549a11.354 11.354 0 00-7.1 2.431z"
                className="cls-15"
                data-name="Path 3254"
                transform="translate(-598.242 -395.618)"
              ></path>
              <path
                d="M2.789 0a2.789 2.789 0 012.79 2.789v2.29a2.789 2.789 0 01-2.789 2.79A2.79 2.79 0 010 5.079v-2.29A2.789 2.789 0 012.789 0z"
                className="cls-3"
                data-name="Rectangle 1973"
                transform="rotate(-38.74 92.528 -24.044)"
              ></path>
              <g data-name="Group 3156" transform="translate(9.26 52.446)">
                <g data-name="Group 3155">
                  <path
                    d="M606.828 494.887c2.773 8.52 2.383 17.988 1.647 26.8-.343 4.115-.842 8.214-1.342 12.313a104.17 104.17 0 00-1.143 10.358c.04 3.874-5.569 3.757-5.61-.12a100.978 100.978 0 01.734-10.238c.482-3.944.962-7.888 1.3-11.847.727-8.437 1.266-17.5-1.393-25.664-1.2-3.692 4.609-5.274 5.8-1.6z"
                    className="cls-11"
                    data-name="Path 3255"
                    transform="translate(-600.379 -492.8)"
                  ></path>
                </g>
              </g>
              <g data-name="Group 3158" transform="translate(22.937 51.047)">
                <g data-name="Group 3157">
                  <path
                    d="M636.463 491.843a93.883 93.883 0 012.552 15.464 47.231 47.231 0 01.507 9.413c-.595 6-1.7 12.088-1.659 18.126.027 3.874-5.992 3.878-6.019 0-.041-5.95 1.035-11.956 1.633-17.865a44.143 44.143 0 00-.395-8.806 87.457 87.457 0 00-2.423-14.731c-1.029-3.74 4.776-5.336 5.8-1.6z"
                    className="cls-11"
                    data-name="Path 3256"
                    transform="translate(-630.538 -489.716)"
                  ></path>
                </g>
              </g>
              <path
                d="M615.934 497.235c-.555.623 1.832-2.407 1.118-1.975-1.489.9-9.845 6.577-11.584 6.606-2.1.034-.876-2-2.439-3.4-1.484-1.331-1.833-3.494-1.978-5.482a3.446 3.446 0 01.153-1.628 3.5 3.5 0 011.2-1.292c3.712-2.81 11.014-9.764 16.2-7.54 5.996 2.571-.01 11.73-2.67 14.711z"
                className="cls-11"
                data-name="Path 3257"
                transform="translate(-591.472 -434.503)"
              ></path>
              <path
                d="M618.212 414.778c1.1 1.417.969 2.293.916 4.227a4.322 4.322 0 01-3.155 4.04l-18.607 5.218s-1.069-7.158 2.6-9.313a651.312 651.312 0 007.453-4.484c3.539-2.154 8.254-2.966 10.793.312z"
                className="cls-14"
                data-name="Path 3258"
                transform="translate(-589.394 -396.518)"
              ></path>
              <path
                d="M620.235 452.953c-.552.716-1.255-19.36-.163-32.062l-20.946 9.966c.106 9.572 1.315 17.125.168 28.79z"
                className="cls-14"
                data-name="Path 3259"
                transform="translate(-590.435 -401.059)"
              ></path>
              <path
                d="M606.553 497.067c5.174-1.887 10.1-3.954 13-7.506 1.423-.821 1.243-2.269-.4-3.217a6.217 6.217 0 00-5.573-.231l-13 7.506c-1.422.821-1.242 2.269.4 3.217a6.216 6.216 0 005.573.231z"
                className="cls-14"
                data-name="Path 3260"
                transform="translate(-590.705 -436.397)"
              ></path>
              <path
                d="M624.014 417.726s2.279-.009 2.706-3.975l1.517-.212c.21.166.648.054.852 1.381a6.029 6.029 0 01-.126 2.371l-.563 2.261a.768.768 0 01-1.174.437z"
                className="cls-6"
                data-name="Path 3261"
                transform="translate(-604.035 -397.041)"
              ></path>
              <g data-name="Group 3159" transform="translate(0 24.779)">
                <path
                  d="M591.742 434.762c-2.347 5.292-8.144 8.871-7.906 15.068.248 6.486 4.415 10.049 8.607 14.407 1.729 1.8-1.007 4.539-2.738 2.739-4.676-4.861-9.043-8.891-9.667-15.992a13.714 13.714 0 012.154-9.23c2.072-3.069 4.678-5.5 6.207-8.946 1.009-2.274 4.347-.306 3.344 1.955z"
                  className="cls-3"
                  data-name="Path 3262"
                  transform="translate(-579.963 -431.798)"
                ></path>
              </g>
              <path
                d="M598.57 430.669l-5.069 9.046c-2.041 2.238-7.208-2.191-5.708-4.173l5.62-8.265a3.094 3.094 0 014.084-.752 3.094 3.094 0 011.073 4.144z"
                className="cls-14"
                data-name="Path 3263"
                transform="translate(-584.095 -403.885)"
              ></path>
              <path
                d="M712.311 438.1c.239.175.769 5.226-3.478 4.408s-3.39-5.066-3.39-5.066 3.371.877 4.3-3.305 2.568 3.963 2.568 3.963z"
                className="cls-12"
                data-name="Path 3264"
                transform="translate(-648.494 -407.643)"
              ></path>
              <path
                d="M712.677 437.259c-.1 1.195.508 2.222 1.364 2.3a1.13 1.13 0 00.277-.01 2.1 2.1 0 001.458-2.02c.1-1.194-.508-2.222-1.364-2.3s-1.633.835-1.735 2.03z"
                className="cls-3"
                data-name="Path 3265"
                transform="translate(-652.48 -408.891)"
              ></path>
              <path
                d="M621.68 410.394l2.131-7.862-5.4 1.5-1.733 6.419c-.378 1.042 3.436 3.949 5.002-.057z"
                className="cls-3"
                data-name="Path 3266"
                transform="translate(-600.01 -391.026)"
              ></path>
              <rect
                width="10.129"
                height="16.398"
                className="cls-3"
                data-name="Rectangle 1974"
                rx="5.064"
                transform="rotate(-178.1 14.078 8.808)"
              ></rect>
              <path
                d="M619.788 384.774a6.437 6.437 0 004.4.094c1.93-.745 3.3-3.456 1.78-4.866-.819-.762-2.918-1.449-4.662-2.158-4.949-2.008-10.313.528-10.5 6.179 0 0-.827 6.984 3.077 8.568a.867.867 0 00.914-.189l.029-.027a.892.892 0 00.193-1.011 2.63 2.63 0 011.27-3.568 1.652 1.652 0 00.7-1.242 2.159 2.159 0 012.799-1.78z"
                className="cls-16"
                data-name="Path 3267"
                transform="translate(-596.778 -377.163)"
              ></path>
              <path
                d="M616.181 403.031a1.029 1.029 0 001.759-.441 2.471 2.471 0 00-.733-2.513 1.029 1.029 0 00-1.759.441 2.47 2.47 0 00.733 2.513z"
                className="cls-3"
                data-name="Path 3268"
                transform="translate(-599.298 -389.505)"
              ></path>
              <path
                d="M626.144 415.029s-2.214-1.134-2.223-1.155a5.844 5.844 0 001.857 2.24z"
                className="cls-17"
                data-name="Path 3269"
                transform="translate(-603.984 -397.224)"
              ></path>
              <path
                d="M618.211 419.05s-2.6.893-5.165-2.751l-1.689 2.1c-.15.322-.167.36.094.644l3.41 3.7a.882.882 0 001.4-.094z"
                className="cls-6"
                data-name="Path 3270"
                transform="translate(-597.06 -398.549)"
              ></path>
            </g>
            <g data-name="Group 3164" transform="translate(79.178 796.727)">
              <g data-name="Group 3162">
                <path
                  d="M402.952 687.472l.02-5.52h-.006a3.453 3.453 0 00-2-2.911l-49.741-28.82c-2.98-1.726-8.189-1.518-11.636.465l-90.21 51.9c-1.792 1.031-2.747 2.339-2.839 3.6l-.015-.01.006.218v.174l.131 4.6c-.461 1.406.133 2.828 1.853 3.824l49.741 28.819a10.684 10.684 0 005.642 1.158 14.672 14.672 0 001.582-.149 13.606 13.606 0 003.043-.813 10.919 10.919 0 001.367-.66l90.209-51.9c1.833-1.055 2.789-2.4 2.843-3.685a2.593 2.593 0 00.01-.29z"
                  className="cls-1"
                  data-name="Path 3271"
                  transform="translate(-240.015 -645.381)"
                ></path>
                <g data-name="Group 3161">
                  <path
                    d="M232.341 771.9l-.142-4.987 6.43 4.173s-1.816 4.521-2.013 4.364-4.275-3.55-4.275-3.55z"
                    className="cls-6"
                    data-name="Path 3272"
                    transform="translate(-232.19 -709.786)"
                  ></path>
                  <path
                    d="M283.928 742.574l-49.741-28.82c-2.98-1.727-2.6-4.734.845-6.716l90.21-51.9c3.446-1.983 8.656-2.191 11.636-.464l49.741 28.82c2.98 1.726 2.6 4.733-.845 6.716l-90.21 51.9c-3.447 1.982-8.656 2.19-11.636.464z"
                    className="cls-6"
                    data-name="Path 3273"
                    transform="translate(-232.179 -647.815)"
                  ></path>
                  <path
                    d="M358.323 714.957l35.853-59.818c3.446-1.983 8.656-2.191 11.636-.464l49.741 28.82c2.98 1.726 2.6 4.733-.845 6.716l-90.21 51.9a13.346 13.346 0 01-5.993 1.623z"
                    className="cls-11"
                    data-name="Path 3274"
                    transform="translate(-301.112 -647.815)"
                  ></path>
                  <path
                    d="M444.87 727.946l9.158-12.389 50.837-2.058-.021 5.81-55.582 17.619z"
                    className="cls-11"
                    data-name="Path 3275"
                    transform="translate(-348.406 -680.599)"
                  ></path>
                  <path
                    d="M337.03 836.6c0 .865-.613 1.213-1.366.776a3.008 3.008 0 01-1.361-2.356c0-.865.613-1.212 1.366-.776a3.007 3.007 0 011.361 2.356z"
                    className="cls-2"
                    data-name="Path 3276"
                    transform="translate(-287.986 -746.477)"
                  ></path>
                  <path
                    d="M283.947 730.027l-49.741-28.82c-2.98-1.726-2.6-4.733.846-6.716l90.209-51.9c3.446-1.983 8.656-2.191 11.635-.464l49.741 28.819c2.98 1.727 2.6 4.734-.845 6.716l-90.209 51.9c-3.447 1.984-8.656 2.191-11.636.465z"
                    className="cls-2"
                    data-name="Path 3277"
                    transform="translate(-232.189 -640.958)"
                  ></path>
                  <path
                    d="M504.539 676.868l-13.739-7.957c-.566-.328-.493-.9.161-1.282a2.478 2.478 0 012.221-.089l13.734 7.958c.567.328.494.9-.161 1.282a2.476 2.476 0 01-2.216.088z"
                    className="cls-18"
                    data-name="Path 3278"
                    transform="translate(-373.3 -655.363)"
                  ></path>
                  <path
                    d="M286.932 809.25l-11.032-6.392a2.044 2.044 0 01-.848-1.626c0-.624.384-.912.851-.642l11.032 6.392a2.044 2.044 0 01.848 1.626c0 .624-.383.913-.851.642z"
                    className="cls-2"
                    data-name="Path 3279"
                    transform="translate(-255.608 -728.136)"
                  ></path>
                  <path
                    d="M541.792 740.584l5.442-3.131a1.41 1.41 0 00.588-1.119c0-.43-.263-.63-.586-.444l-5.436 3.13a1.409 1.409 0 00-.588 1.119c-.006.431.258.631.58.445z"
                    className="cls-2"
                    data-name="Path 3280"
                    transform="translate(-401.051 -692.797)"
                  ></path>
                  <path
                    d="M524.775 751.234l5.442-3.13a1.411 1.411 0 00.588-1.12c0-.43-.263-.63-.586-.444l-5.441 3.13a1.41 1.41 0 00-.588 1.12c-.001.43.262.63.585.444z"
                    className="cls-2"
                    data-name="Path 3281"
                    transform="translate(-391.752 -698.617)"
                  ></path>
                  <path
                    d="M350.571 650.712l-75.858 43.642a2.647 2.647 0 00-.007 4.586l45.974 26.638a2.648 2.648 0 002.648 0l75.857-43.642a2.648 2.648 0 00.007-4.586l-45.975-26.637a2.647 2.647 0 00-2.646-.001z"
                    className="cls-19"
                    data-name="Path 3282"
                    transform="translate(-254.697 -646.096)"
                  ></path>
                </g>
              </g>
              <g data-name="Group 3163" transform="translate(68.501 16.756)">
                <path
                  d="M430.889 682.667c-10.954-6.325-28.659-6.356-39.544-.072s-10.83 16.507.124 22.831 28.659 6.357 39.544.072 10.83-16.506-.124-22.831zm-32.928 19.011c-7.345-4.241-7.383-11.1-.083-15.31s19.172-4.193 26.518.048 7.383 11.1.083 15.31-19.173 4.193-26.518-.048z"
                  className="cls-2"
                  data-name="Path 3283"
                  transform="translate(-383.216 -677.903)"
                ></path>
                <path
                  d="M448.077 721.679c6.662-3.846 9.219-9.167 7.695-14.166l-9.037 1c.954 3.322-.771 6.841-5.191 9.394-6.043 3.488-15.215 4.071-22.363 1.765l-4.737 4.547c10.699 3.548 24.543 2.709 33.633-2.54z"
                  className="cls-20"
                  data-name="Path 3284"
                  transform="translate(-400.281 -694.084)"
                ></path>
                <path
                  d="M447.585 677.985l-.717 5.3a26.761 26.761 0 0112.311 3.178c3.1 1.791 4.9 4.049 5.388 6.376l9.174-.459c-.687-3.527-3.373-6.954-8.069-9.666a39.753 39.753 0 00-18.087-4.729z"
                  className="cls-6"
                  data-name="Path 3285"
                  transform="translate(-417.999 -677.948)"
                ></path>
              </g>
              <path
                d="M365.46 750.274l-21.129-12.2c-1.388-.8-1.213-2.215.391-3.14a6.067 6.067 0 015.439-.225l21.129 12.2c1.388.8 1.213 2.215-.391 3.14a6.066 6.066 0 01-5.439.225z"
                className="cls-6"
                data-name="Path 3286"
                transform="translate(-292.954 -691.894)"
              ></path>
              <path
                d="M342.474 762.821l-21.13-12.2c-1.388-.8-1.212-2.214.39-3.14a6.065 6.065 0 015.439-.225l21.13 12.2c1.388.8 1.212 2.215-.391 3.14a6.067 6.067 0 01-5.438.225z"
                className="cls-6"
                data-name="Path 3287"
                transform="translate(-280.392 -698.75)"
              ></path>
            </g>
            <g data-name="Group 3168" transform="translate(219.434 789.024)">
              <path
                d="M524.85 280.776L423.663 339.2c-2.016 1.164-5.537 1.018-7.864-.326l-23.229-13.412c-2.328-1.344-2.58-3.377-.565-4.54L493.191 262.5c2.016-1.164 5.537-1.018 7.864.326l23.229 13.411c2.328 1.342 2.581 3.376.566 4.539z"
                className="cls-1"
                data-name="Path 3288"
                transform="translate(-390.644 -206.237)"
              ></path>
              <path
                d="M524.85 264.322l-101.187 58.42c-2.016 1.164-5.537 1.018-7.864-.326L392.569 309c-2.328-1.344-2.58-3.377-.565-4.541l101.187-58.42c2.016-1.164 5.537-1.018 7.864.326l23.229 13.411c2.328 1.349 2.581 3.382.566 4.546z"
                className="cls-21"
                data-name="Path 3289"
                transform="translate(-390.644 -197.246)"
              ></path>
              <path
                d="M602.783 184.355l19.643-11.381-19.594-11.315-19.66 11.351z"
                className="cls-22"
                data-name="Path 3290"
                transform="translate(-495.853 -151.561)"
              ></path>
              <path
                d="M626.375 239.72l19.66-11.351v-41.762l-19.66 11.351z"
                className="cls-23"
                data-name="Path 3291"
                transform="translate(-519.462 -165.195)"
              ></path>
              <path
                d="M602.35 239.72l-20.075-11.351v-41.762l20.075 11.351z"
                className="cls-24"
                data-name="Path 3292"
                transform="translate(-495.363 -165.195)"
              ></path>
              <path
                d="M544.963 162.026l19.886-11.3-19.764-11.326-19.66 11.351z"
                className="cls-20"
                data-name="Path 3293"
                transform="translate(-464.297 -139.396)"
              ></path>
              <path
                d="M588.3 164.344v66.616l-19.66 11.351V175.7z"
                className="cls-25"
                data-name="Path 3294"
                transform="translate(-487.911 -153.029)"
              ></path>
              <path
                d="M544.721 175.7l.015 66.615-19.742-11.355v-66.616z"
                className="cls-24"
                data-name="Path 3295"
                transform="translate(-464.061 -153.029)"
              ></path>
              <path
                d="M489.706 251.339l19.529-11.368-19.535-11.28-19.66 11.351z"
                className="cls-26"
                data-name="Path 3296"
                transform="translate(-434.029 -188.192)"
              ></path>
              <path
                d="M513.277 306.753l19.485-11.353v-41.76l-19.484 11.351z"
                className="cls-27"
                data-name="Path 3297"
                transform="translate(-457.658 -201.826)"
              ></path>
              <path
                d="M489.8 306.753L470.316 295.4v-41.76l19.484 11.351z"
                className="cls-24"
                data-name="Path 3298"
                transform="translate(-434.182 -201.826)"
              ></path>
              <path
                d="M431.7 320.733l19.66-11.351-19.541-11.282-19.66 11.351z"
                className="cls-6"
                data-name="Path 3299"
                transform="translate(-402.401 -226.123)"
              ></path>
              <path
                d="M431.808 360.928l-20.075-12.034v-25.816l20.075 11.351z"
                className="cls-24"
                data-name="Path 3300"
                transform="translate(-402.168 -239.771)"
              ></path>
              <g data-name="Group 3167" transform="translate(29.266 83.259)">
                <g data-name="Group 3166">
                  <g data-name="Group 3165">
                    <path
                      d="M455.173 360.822v-26.5l19.66-11.351v26.5z"
                      className="cls-11"
                      data-name="Path 3301"
                      transform="translate(-455.173 -322.972)"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </SvgIcon>
  )
}

export default ContactFormSvg
