import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"

import { buttonTwo, buttonTwoAlt } from "../../Utilities"

const ButtonStyled = styled(Link)`
  ${buttonTwo};
  ${props => props.color === "alt" && buttonTwoAlt}
`

const LinkTwo = ({ color, children, url }) => {
  return (
    <>
      <ButtonStyled to={url} color={color}>
        {children}
      </ButtonStyled>
    </>
  )
}

LinkTwo.propTypes = {
  children: PropTypes.node.isRequired,
  url: PropTypes.node.isRequired,
}

export default LinkTwo
